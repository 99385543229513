<template>
  <div class="confirmOrder">
    <MyMenu :menuname="'other'"></MyMenu>
    <!-- 头部 -->
    <Stepbar :active="0" :enterprisevalue="enterprisevalue"></Stepbar>
    <!-- 头部END -->

    <!-- 主要内容容器 -->
    <div class="content">
      <!-- 选择地址 -->
      <div class="section-item">
        <p class="title">收货地址</p>
        <MyAddress @addressnum="addressnum"></MyAddress>
      </div>
      <!-- 选择地址END -->
      <!-- 商品及优惠券 -->
      <div class="section-item">
        <p class="title">商品信息</p>
        <div class="goods-list">
          <div class="goods-dsc">
            <span class="dsc-title">商品名称</span>
            <span class="dsc-details">配置详情</span>
            <span class="dsc-month">租赁期数</span>
            <span class="dsc-rent">月租金</span>
          </div>
          <ul>
            <li>
              <img :src="currentCommodityDetail.skuImage" />
              <span class="pro-name">{{ currentCommodityDetail.title }}</span>
              <span class="pro-price">【{{ currentCommodityDetail.properties[0].propertyValue }}】</span>
              <span class="pro-status">{{ currentCommodityDetail.returnablePeriods }}月</span>
              <span class="pro-monthrent">
                <i v-if="currentCommodityDetail.rent_1_12 * 1 > 0">1-12 期月租金: ￥{{ currentCommodityDetail.rent_1_12 }}</i>
                <i v-if="currentCommodityDetail.rent_13_24 * 1 > 0">13-24 期月租金: ￥{{ currentCommodityDetail.rent_13_24 }}</i>
                <i v-if="currentCommodityDetail.rent_25_36 * 1 > 0">25-36 期月租金: ￥{{ currentCommodityDetail.rent_25_36 }}</i>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 商品及优惠券END -->
      <!-- 配送方式 -->
      <div class="section-item">
        <p class="title">设备数量</p>
        <p class="Number"><el-input-number v-model="commodityNumber" @change="handleChange" :min="1" :max="99"></el-input-number></p>
      </div>
      <!-- 配送方式END -->

      <!-- 配送方式 -->
      <div class="section-item">
        <p class="title">配送方式</p>
        <p class="shipment">寄出出租方包邮</p>
        <p class="delivery">预计发货日：</p>
        <p class="delivery">
          <el-date-picker v-model="expectDeliveryDate" :picker-options="datePickerOptions" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="small" placeholder="选择日期"></el-date-picker>
        </p>
      </div>
      <!-- 配送方式END -->

      <!-- 企业个人 -->
      <!-- <div class="section-item">
        <p class="title">租赁方式</p>
        <p :class="['invoice', enterprisevalue == 1 ? 'hover' : '']" @click="switchto(1)">个人租赁</p>
        <p :class="['invoice', enterprisevalue == 0 ? 'hover' : '']" @click="switchto(0)">企业租赁</p>
      </div> -->
      <!-- 买家留言 -->
      <div class="section-item">
        <p class="title">推广渠道</p>
        <el-input style="width: 20%;" placeholder="填写您推荐码(如有)" v-model="adChannel"></el-input>
      </div>
      <!-- 买家留言 -->
      <div class="section-item">
        <p class="title">备注信息</p>
        <el-input type="textarea" class="desc" placeholder="填写您对订单的其他要求" v-model="orderRemark"></el-input>
      </div>

      <!-- 结算列表 -->
      <div class="section-count">
        <div class="money-box">
          <ul>
            <li>
              <span class="title">首期费用：</span>
              <span class="value">￥{{ dealNumber(currentCommodityDetail.advancePayRent * commodityNumber) }}</span>
            </li>
            <li>
              <span class="title">月租金：</span>
              <span class="value">￥{{ dealNumber(currentCommodityDetail.rent * commodityNumber) }}</span>
            </li>
            <li>
              <span class="title">剩余租金：</span>
              <span class="value">￥{{ dealNumber(currentCommodityDetail.sumPurenessRent * commodityNumber - currentCommodityDetail.advancePayRent * commodityNumber) }}</span>
            </li>
            <li>
              <span class="title">合同总租金：</span>
              <span class="value">￥{{ dealNumber(currentCommodityDetail.sumPurenessRent * commodityNumber) }}</span>
            </li>
            <li>
              <span class="title">订单押金：</span>
              <span class="value">￥{{ dealNumber(currentCommodityDetail.depositPrice * commodityNumber) }}</span>
            </li>
            <li class="total">
              <span class="title">应付总额：</span>
              <span class="value">
                <span class="total-price">￥{{ dealNumber(currentCommodityDetail.advancePayRent * commodityNumber + currentCommodityDetail.depositPrice * commodityNumber) }}</span>
                元
              </span>
            </li>
          </ul>
        </div>
      </div>

      <!-- 结算列表END -->

      <!-- 结算导航 -->
      <div class="section-bar">
        <div class="Read-protocol">
          <el-checkbox v-model="checked">我已同意并阅读</el-checkbox>
          <el-link type="primary" @click="onhelp('UserAggrement')">《用户信息查询授权书》</el-link>
          <el-link type="primary" @click="onhelp('PrivacyAggrement')">《隐私政策》</el-link>
          <el-link v-if="enterprisevalue == 1" type="primary" @click="onhelp('Service')">《租赁服务协议》</el-link>
          <el-link v-else type="primary" @click="onhelp('frameService')">《租赁服务协议》</el-link>
        </div>
        <div class="btn">
          <el-button @click="goback" class="btn-return">返回</el-button>
          <el-button @click="addOrder(1)" :disabled="!checked" type="primary" class="btn-primary">立即租赁</el-button>
          <el-badge class="btn-primary" :value="'只需支付首期费用' + dealNumber(currentCommodityDetail.advancePayRent * commodityNumber)">
            <el-button @click="addOrder(0)" :disabled="!checked" type="primary">选择企业免押租赁</el-button>
          </el-badge>
        </div>
      </div>
      <!-- 结算导航END -->
    </div>
    <!-- 主要内容容器END -->
  </div>
</template>
<script>
import Stepbar from './item/Stepbar.vue'
import MyAddress from '../../components/MyAddress.vue'

export default {
  name: '',
  components: { MyAddress, Stepbar },
  data() {
    return {
      checked: false,
      enterprisevalue: 0, //企业or个人
      expectDeliveryDate: '',
      orderRemark: '',
      startDate: '',
      endDate: '',
      adChannel: '',
      commodityNumber: 1,
      currentCommodityDetail: {},
      addresslen: '',
      isAuth: false
    }
  },
  created() {
    var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
    if (currentCommodityDetail) {
      this.currentCommodityDetail = currentCommodityDetail
      this.checkIsAuthentication()
    } else {
      this.goback()
    }
  },
  computed: {
    // 商品详情
    datePickerOptions() {
      var _this = this
      let startDate
      let endDate
      // 开始为当前日期判断4点前和4点后
      const currentDate = new Date() // Get the current date and time
      const currentHour = currentDate.getHours() // Get the current hour (in 24-hour format)
      // 1天
      var d = 1000 * 60 * 60 * 24
      if (currentHour > 16) {
        startDate = currentDate.getTime() + d
      } else {
        startDate = currentDate.getTime()
      }
      // 如果是预售 加7天
      if (_this.currentCommodityDetail.presaleCommodity == 1) {
        startDate = currentDate.getTime() + d * 7
      }
      // 结束为当前日期的3个月后
      endDate = currentDate.getTime() + d * 90

      var time = new Date(startDate)
      var year = time.getFullYear() //年
      var month = ('0' + (time.getMonth() + 1)).slice(-2) //月
      var day = ('0' + time.getDate()).slice(-2) //日
      var expectDeliveryDate = year + '-' + month + '-' + day
      _this.expectDeliveryDate = expectDeliveryDate

      return {
        disabledDate(time) {
          return time.getTime() < startDate - d || time.getTime() > endDate
        }
      }
    }
  },

  methods: {
    // chakan
    onhelp(name) {
      this.$router.push({ path: '/help/' + name })
    },

    // goback
    goback() {
      this.$router.go(-1) //返回上一页
    },

    handleChange(value) {
      this.commodityNumber = value
    },

    // 企业or个人
    switchto(val) {
      this.enterprisevalue = val
    },

    addressnum(e) {
      this.addresslen = e
    },

    checkIsAuthentication() {
      this.$axios.get('/zuwu-api/webOfficial/isAuthentication').then((res) => {
        if (res.data.code == 200) {
          this.isAuth = true
        } else {
          this.isAuth = false
        }
      })
    },

    addOrder(type) {
      this.enterprisevalue = type || 0
      // 企业订单判断框架协议是否签署
      if (this.isAuth || this.enterprisevalue == 0) {
        if (this.addresslen == 1) {
          this.currentCommodityDetail.expectDeliveryDate = this.expectDeliveryDate
          this.currentCommodityDetail.orderRemark = this.orderRemark
          this.currentCommodityDetail.isEnterpriseOrder = this.enterprisevalue == 0 ? true : false
          this.currentCommodityDetail.commodityNumber = this.commodityNumber
          this.currentCommodityDetail.adChannel = this.adChannel

          // 商品详情存储
          localStorage.setItem('currentCommodityDetail', JSON.stringify(this.currentCommodityDetail))

          if (this.currentCommodityDetail.isEnterpriseOrder) {
            this.$router.push({ path: '/Enterprise' })
          } else {
            this.$router.push({ path: '/Collating' })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '请先填写收货地址'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请先实名认证'
        })
        setTimeout(() => {
          this.$router.push({ path: '/User/realname' })
        }, 300)
      }
    },

    dealNumber(num) {
      if (num != 0 && num) {
        num = num + ''
        let decimalsStr = ''
        let splitList = num.split('.')
        //先处理小数部分
        if (splitList[1]) {
          //如果有2位小数则保留2位，只有1位则添0
          decimalsStr = splitList[1].substring(0, 2).length == 2 ? splitList[1].substring(0, 2) : splitList[1].substring(0, 2) + '0'
          // if (decimalsStr)
        } else {
          decimalsStr = '00'
        }
        //整数部分
        //将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
        let intStrList = splitList[0]
          .split('')
          .reverse()
          .join('')
          .match(/(\d{1,3})/g)
        //将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
        let intStr = intStrList
          .join(',')
          .split('')
          .reverse()
          .join('')
        return intStr + '.' + decimalsStr
      }
      return num
    }
  }
}
</script>
<style scoped>
.confirmOrder {
  background-color: #f5f5f5;
  padding-bottom: 20px;
}
/* 主要内容容器CSS */
.confirmOrder .content {
  width: 1225px;
  margin: 0 auto;
  padding: 30px 0 0;
  background-color: #fff;
}

/* 展示列表统用样式 */
.section-item {
  margin: 0 48px;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.section-item .title {
  color: #333;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  width: 150px;
  float: left;
}
.section-item .goods-list {
  width: 100%;
  overflow: hidden;
}
.section-item .goods-list li {
  padding: 10px 0;
  color: #424242;
  overflow: hidden;
}
.section-item .goods-list li img {
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.section-item .goods-list li .pro-name {
  float: left;
  width: 330px;
  padding-top: 15px;
  line-height: 25px;
  font-size: 16px;
}
.section-item .goods-list li .pro-price {
  float: left;
  font-size: 14px;
  width: 400px;
  padding-top: 20px;
  line-height: 40px;
  text-align: center;
}
.section-item .goods-list li .pro-status {
  float: left;
  width: 100px;
  height: 40px;
  text-align: center;
  padding-top: 20px;
  line-height: 40px;
}
.section-item .goods-list li .pro-monthrent {
  float: left;
  width: 170px;
  font-size: 12px;
  text-align: right;
  padding-top: 10px;
  line-height: 20px;
}
.section-item .goods-list li .pro-monthrent i {
  display: block;
  font-style: normal;
}

.section-item .goods-dsc {
  background: #e0e0e0;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
}
.section-item .goods-dsc .dsc-title {
  float: left;
  width: 450px;
}
.section-item .goods-dsc .dsc-details {
  float: left;
  width: 400px;
}
.section-item .goods-dsc .dsc-month {
  float: left;
  width: 100px;
}
.section-item .goods-dsc .dsc-rent {
  float: left;
  width: 170px;
}

.section-item .shipment {
  float: left;
  line-height: 38px;
  font-size: 14px;
  background: #f7fcff;
  color: #1e95d4;
  padding: 0 10px;
  margin-right: 10px;
  border: 1px solid #1e95d4;
}

.section-item .delivery {
  float: left;
  line-height: 38px;
  font-size: 14px;
  color: #666;
}
.section-item .delivery a {
  color: #1e95d4;
  cursor: pointer;
}

.section-item .desc {
  float: left;
  width: 976px;
}

.section-item .invoice {
  float: left;
  line-height: 38px;
  font-size: 14px;
  padding: 0 20px;
  margin-right: 20px;
  color: #333;
  border: 1px solid #e0e0e0;
}
.section-item .hover {
  color: #1e95d4;
  background: #f7fcff;
  border: 1px solid #1e95d4;
}
/* 发票CSS END */

/* 结算列表CSS */
.section-count {
  margin: 0 48px;
  padding: 20px 0;
  overflow: hidden;
}
.section-count .money-box {
  float: right;
  text-align: right;
}
.section-count .money-box .title {
  float: left;
  width: 126px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #757575;
}
.section-count .money-box .value {
  float: left;
  min-width: 200px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #1e95d4;
}
.section-count .money-box .total .title {
  padding-top: 15px;
}
.section-count .money-box .total .value {
  padding-top: 10px;
}
.section-count .money-box .total-price {
  font-size: 30px;
}
/* 结算列表CSS END */

/* 结算导航CSS */
.section-bar {
  padding: 20px 48px 50px;
  border-top: 2px solid #f5f5f5;
  overflow: hidden;
}
.section-bar .btn {
  float: right;
}

.section-bar /deep/ .el-button {
  padding: 12px 50px;
  margin-left: 20px;
}

.section-bar /deep/ .el-badge__content.is-fixed {
  right: 120px;
}

.Read-protocol {
  text-align: right;
  padding-bottom: 20px;
  font-size: 14px;
  color: #999;
}

.delivery /deep/ .el-input__inner {
  border: none;
  color: #ff5b50;
  cursor: pointer;
  font-weight: bold;
}
</style>
